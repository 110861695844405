import styled from "styled-components";

const MessageInputStyled = styled.div`
  form {
    display: flex;
    justify-content: center;
  }
`;

export default MessageInputStyled;
